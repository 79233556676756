var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-subheader',[_vm._v("Tipos de estudio")]),_c('v-divider'),_c('div',{staticClass:"pa-1"},[_c('DonutChart',{attrs:{"chartdata":_vm.tipoEstudios}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-subheader',[_vm._v("Tarifas de estudio")]),_c('v-divider'),_c('div',{staticClass:"pa-1"},[_c('HBarChart',{attrs:{"chartdata":_vm.tarifasEstudio,"options":{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                } ],
            },
          }}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }