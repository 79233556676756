<template>
  <v-card class="pa-4" v-if="!loading">
    <v-row>
      <v-col cols="12" md="6">
        <v-subheader>Tipos de estudio</v-subheader>
        <v-divider />
        <div class="pa-1">
          <DonutChart :chartdata="tipoEstudios" />
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-subheader>Tarifas de estudio</v-subheader>
        <v-divider />
        <div class="pa-1">
          <HBarChart
            :chartdata="tarifasEstudio"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }"
          />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Chart from "@/components/charts/index.js";
import pallete from "@/utils/colorPallete.js";

export default {
  props: {
    idUsuario: String | Number,
  },
  components: {
    DonutChart: Chart("DonutChart"),
    HBarChart: Chart("horizontalBar"),
  },
  data() {
    return {
      loading: true,
      tipoEstudios: {},
      tarifasEstudio: {},
    };
  },
  methods: {
    async getStats() {
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/stats/getStats.php`,
        params: {
          idUsuario: this.idUsuario,
        },
      });
      return data;
    },
    hash(src) {
      let hash = 0;
      if (src.length === 0) return hash;
      for (let i = 0; i < src.length; i++) {
        let chr = src.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
  },
  async mounted() {
    if (!this.idUsuario) return;
    const { tiposEstudio, tarifasEstudio } = await this.getStats();

    const auxTipoEstudios = {
      labels: [],
      datasets: [{ data: [], hoverBackgroundColor: [], backgroundColor: [] }],
    };

    for (const tipo of tiposEstudio) {
      auxTipoEstudios.labels.push(tipo.tipo);
      auxTipoEstudios.datasets[0].data.push(tipo.num);
      let color =
        pallete[
          ((this.hash(tipo.tipo) % pallete.length) + pallete.length) %
            pallete.length
        ];
      auxTipoEstudios.datasets[0].hoverBackgroundColor.push(color);
      auxTipoEstudios.datasets[0].backgroundColor.push(color + "a0");
    }
    this.tipoEstudios = auxTipoEstudios;

    const auxTarifasEstudio = {
      labels: [],
      datasets: [{ data: [], hoverBackgroundColor: [], backgroundColor: [] }],
    };

    for (const tarifa of tarifasEstudio) {
      let color =
        pallete[
          ((this.hash(tarifa.tarifa) % pallete.length) + pallete.length) %
            pallete.length
        ];

      auxTarifasEstudio.labels.push(tarifa.tarifa)

      auxTarifasEstudio.datasets[0].data.push(tarifa.num)
      auxTarifasEstudio.datasets[0].hoverBackgroundColor.push(color)
      auxTarifasEstudio.datasets[0].backgroundColor.push(color + 'a0')

    }
    this.tarifasEstudio = auxTarifasEstudio;

    this.loading = false;
  },
};
</script>
